import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import commonApi from 'api/commonApi';
import StorageKeys from 'constants/storage-keys';
import { showNotifyErrorCatch } from 'utils/logger';

// ---------- Thunk API ---------- //

export const getSettings = createAsyncThunk('common/getSettings', async (thunkAPI) => {
  const response = await commonApi.getSettings();
  const newResponse = { ...response.data, country_code: response.data.country_code.toLowerCase() };
  return newResponse;
});
export const getCOCAccount = createAsyncThunk('common/getCOCAccount', async (thunkAPI) => {
  const response = await commonApi.getCOCAccount();
  return response;
});

export const editCOCAccount = createAsyncThunk('common/editCOCAccount', async (body: FormData) => {
  try {
    const response = await commonApi.editCOCAccount(body);
    return response;
  } catch (error) {
    showNotifyErrorCatch(error);
  }
});

// ---------- Init Reducer ---------- //

const initialState = JSON.parse(localStorage.getItem(StorageKeys.CONFIG_REDUCER) as string) || {
  loading: false,
  languageCode: 'en',
  settings: {},
  cocAccount: {},
};

// ---------- Slice ---------- //

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    ...initialState,
    loading: false,
  },
  reducers: {
    setGlobalLoading: (state, action) => {
      const showLoading = action.payload;
      state.loading = showLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettings.fulfilled, (state, action: any) => {
      state.settings = action.payload;
    });
    builder.addCase(getCOCAccount.fulfilled, (state, action: any) => {
      state.cocAccount = action.payload.object;
    });
    builder.addCase(editCOCAccount.fulfilled, (state, action: PayloadAction<any>) => {
      const cocAccount = action.payload.object;

      const newCocAccount = {
        ...cocAccount,
        avatar: cocAccount.avatar
          ? {
              ...cocAccount.avatar,
              image_url: cocAccount.avatar.file_url,
            }
          : null,
      };

      state.cocAccount = newCocAccount;
    });
  },
});

const { reducer: commonReducer, actions } = commonSlice;
export const { setGlobalLoading } = actions;
export default commonReducer;
