import { Avatar, Dropdown, Menu } from 'antd';
import { RootState } from 'app/store';
import { CALLBACK_URL } from 'constants/app';
import StorageKeys from 'constants/storage-keys';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Routing from 'routes';
import { ReactComponent as ChevronDown } from 'assets/images/chevron-down.svg';
import { ReactComponent as BlankAvatar } from 'assets/images/blank-avatar.svg';

import cn from 'classnames';

import './Header.scss';
import EditProfileModal from './EditProfileModal';
import { injectAuthToDomain } from 'utils/injectAuthToDomain';

export type ProfileProps = { className?: string; lang?: string };

export const Profile: React.FC<ProfileProps> = ({ className, lang }) => {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const [isLeft, setIsLeft] = useState(false);
  const cocAccount = useSelector((state: RootState) => state.common.cocAccount);
  const [profile, setProfile] = useState(null);

  function handleLogout() {
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
    const url = `${injectAuthToDomain()}/coc_accounts/sign_out?redirect_uri=${CALLBACK_URL}${
      Routing.SUCCESS_LOGIN.path
    }/&lang=${lang}`;
    window.location.href = url;
  }

  function handleOpen() {
    setProfile(cocAccount);
  }

  function handleClose() {
    setProfile(null);
  }

  const menus = (
    <Menu className="profile-menu">
      <Menu.Divider className="profile-menu__divider" />
      <Menu.Item onClick={handleOpen}>{t('common.editProfile')}</Menu.Item>
      <Menu.Item onClick={handleLogout}>{t('common.log_out')}</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menus}
        getPopupContainer={() => document.getElementsByClassName('profile')[0] as HTMLElement}
        visible={isHovering}
        onVisibleChange={setIsHovering}
        trigger={['click']}
      >
        <div
          className={cn('profile', {
            'profile--active': isHovering,
            'profile--left': isHovering && isLeft,
          })}
          onMouseLeave={() => setIsLeft(true)}
          onMouseEnter={() => setIsLeft(false)}
        >
          <span className="profile__inner">
            <Avatar size={37} shape="square" src={cocAccount.avatar?.image_url} icon={<BlankAvatar />} />
            <span className="profile__name">{cocAccount.name}</span>
            <ChevronDown className="chevron" />
          </span>
        </div>
      </Dropdown>
      <EditProfileModal visible={!!profile} data={profile} onClose={handleClose} />
    </>
  );
};

export default Profile;
