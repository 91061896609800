import { Layout, message, Spin, Tooltip } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { getCOCAccount, getSettings } from 'app/commonSlice';
import { RootState } from 'app/store';
import Loading from 'components/Common/Loading';
import Header from 'components/Header';
import RouteList from 'components/RouteList';
import { CALLBACK_URL } from 'constants/app';
import StorageKeys from 'constants/storage-keys';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routing from 'routes';
import './App.scss';
import { injectAuthToDomain } from 'utils/injectAuthToDomain';
import moment, { isMoment } from 'moment';
import { ReactComponent as ClockIcon } from 'assets/images/clock.svg';
import cx from 'classnames';
import { formatDateTime } from 'utils/common';

function getProfileTimeZone(time_zone) {
  if (!time_zone) {
    return null;
  }

  const profileTimeZone = moment().utcOffset(time_zone);
  return profileTimeZone;
}

// Doesn't allow stacking up messages
message.config({
  maxCount: 1,
  duration: 5,
});

const { Content } = Layout;

function App() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const showGlobalLoading = useSelector((state: RootState) => state.common.loading);
  const { time_zone } = useSelector((state: RootState) => state.common.settings?.settings || {});

  const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  /*------------------------------------------------------*/
  /*------------------ LIFE CYCLE HOOKS ------------------*/
  /*------------------------------------------------------*/

  useEffect(() => {
    // Redirect user to live-map page if pathname is /

    if (location.pathname === '/') {
      const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
      if (!accessToken) {
        // TODO:
        // - Handle callback from other url to detect token of user use for this project
        //
        // SPECS:
        // - Workflow login
        // -- Because we don't have a login page in project so if user want to use all features
        // ---- in project. We need to redirect user to another domain to process login
        // -- After user successful login. In this project wil receive the callback and
        // ---- update token from callback to local storage

        const url = `${injectAuthToDomain()}/coc_accounts/sign_in?redirect_uri=${CALLBACK_URL}${
          Routing.SUCCESS_LOGIN.path
        }/`;
        window.location.href = url;
      } else {
        history.push(Routing.LIVE_MAP.path);
      }
    }
  }, [history, location.pathname]);

  useEffect(() => {
    if (location.pathname !== Routing.SUCCESS_LOGIN.path) {
      if (accessToken) {
        dispatch(getSettings());
        dispatch(getCOCAccount());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /*--------------------------------------------*/
  /*------------------ LAYOUT ------------------*/
  /*--------------------------------------------*/

  const currentTimeZoneOffset = moment().utcOffset();
  const profileTimeZone = getProfileTimeZone(time_zone);
  const showTimeZone = profileTimeZone ? currentTimeZoneOffset !== profileTimeZone.utcOffset() : false;

  return (
    <>
      <Helmet>
        <title>{t('common.recipient_dashboard')}</title>
      </Helmet>

      <Layout className={cx('app', { 'app--has-footer': showTimeZone })}>
        <Header location={location} />
        <Layout className="layout">
          <Content className="layout__content">
            <Suspense fallback={<Spin className="globalLoading" spinning={true} />}>
              <RouteList routes={Routing} />
            </Suspense>
          </Content>
        </Layout>

        {showGlobalLoading && <Loading />}

        <ToastContainer
          draggable
          closeOnClick
          pauseOnHover
          hideProgressBar
          position="top-center"
          autoClose={5000}
          newestOnTop={false}
          rtl={false}
        />
      </Layout>
      {isMoment(profileTimeZone) && showTimeZone && (
        <div className="timezone">
          <Tooltip
            overlayClassName="timezone__tooltip"
            title={<span>{formatDateTime(profileTimeZone).dayOfWeekAndDateTime}</span>}
          >
            <div>
              <ClockIcon />
              <span className="timezone__text">
                {t('common.gmtTimeZone', { timeZone: profileTimeZone.format('Z') })}
              </span>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default App;
