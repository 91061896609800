import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import bookingApi from 'api/bookingApi';
import vehiclesApi from 'api/vehiclesApi';
import axios, { AxiosResponse } from 'axios';
import { TABLE_ROW } from 'constants/common';
import { checkPeriodToShowVehicle } from 'utils/common';
import { showNotifyErrorCatch } from 'utils/logger';

type BookingType = {
  id?: number;
  tallyData?: any;
};

type AxiosResponseType = {
  pagination: Object;
} & AxiosResponse;

// ---------- Thunk API ---------- //

export const getBookings = createAsyncThunk('liveMap/getBookings', async (params: Object, { signal }) => {
  try {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });

    const { data: resultBookings, pagination } = (await bookingApi.getBookings({
      ...params,
      cancelToken: source.token,
    })) as AxiosResponseType;

    if (resultBookings) {
      // After done fetch API get list bookings - With result receive
      // We will call get list locations and vehicles of each booking
      const vehiclesIds: Array<number> = resultBookings.map((booking) => booking.vehicle_id);

      const payloadVehicles = {
        params: {
          'vehicle_ids[]': Array.from(new Set(vehiclesIds)),
        },
      };
      const { data: resultVehicles } = await vehiclesApi.getVehicles(payloadVehicles);
      const { data: resultVehicleTypes } = await vehiclesApi.getVehicleTypes();

      //depend on the time setting in vehicle we show the vehicle on map
      resultBookings.forEach((booking) => {
        const { pickup_time, vehicle_type: { settings } } = booking;
        booking.showOnMap = checkPeriodToShowVehicle(pickup_time, settings?.sending_eta_sms_period);
      });

      // Save data to store
      return {
        bookings: resultBookings,
        vehicles: resultVehicles,
        vehicleTypes: resultVehicleTypes,
        pagination,
      };
    }
  } catch (error) {
    showNotifyErrorCatch(error);
  }
});

export const getVehicleTypes = createAsyncThunk('liveMap/getVehicleTypes', async (thunkAPI) => {
  const response = await vehiclesApi.getVehicleTypes();
  return response.data;
});

export const getStatistic = createAsyncThunk('liveMap/getStatistic', async (params: Object, thunkAPI) => {
  try {
    return await bookingApi.getStatistic(params);
  } catch (error) {
    showNotifyErrorCatch(error);
  }
});

// ---------- Init Reducer ---------- //

const initialState = {
  bookings: {
    list: [],
    vehicles: [],
    vehicleTypes: [],
    locations: [],
    loading: false,
    error: false,
    requests: {},
  },
  pagination: {},
  locations: {
    list: [],
    loading: false,
    error: false,
  },
  vehicles: {
    list: [],
    loading: false,
    error: false,
  },
  vehicleTypes: {
    list: [],
    loading: false,
    error: false,
  },
  statistic: {
    list: {},
    loading: false,
    error: false,
  },
  rowSelected: null,
  loading: false,
  paramSearch: {},
  typeClick: TABLE_ROW,
  defaultValue: {},
  defaultStatistic: {},
};

// ---------- Slice ---------- //

const liveMapSlice = createSlice({
  name: 'liveMap',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ value: boolean }>) => {
      state.loading = action.payload.value;
    },
    setParamSearch: (state, action) => {
      state.paramSearch = { ...action.payload.params };
    },
    setRowSelected: (state, action) => {
      state.rowSelected = action.payload;
    },
    setTypeClick: (state, action) => {
      state.typeClick = action.payload;
    },
    setDefaultValue: (state, action) => {
      state.defaultValue = action.payload;
    },
    setDefaultStatistic: (state, action) => {
      state.defaultStatistic = action.payload;
    },
    updateBookings: (state, action) => {
      const { list } = state.bookings;

      const { id, showOnMap } = action.payload;
      const newList = list.map((booking: any) => {
        if (booking.id === id) {
          booking.showOnMap = showOnMap;
        }
        return booking;
      });
      state.bookings = {
        ...state.bookings,
        //@ts-ignore
        list: newList,
      };
    },
  },
  extraReducers: (builder) => {
    // Get Bookings
    builder.addCase(getBookings.pending, (state, action) => {
      state.bookings.loading = true;
      (state.bookings.list as BookingType[]).forEach((booking) => {
        delete booking.tallyData;
      });
    });

    builder.addCase(getBookings.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.bookings = {
          ...state.bookings,
          list: action.payload.bookings,
          vehicles: action.payload.vehicles,
          vehicleTypes: action.payload.vehicleTypes,
          locations: action.payload.locations,
          loading: false,
        };
        state.pagination = { ...action.payload.pagination };
      }
    });

    // End Get Bookings

    // Get vehicleTypes
    builder.addCase(getVehicleTypes.pending, (state, action) => {
      state.vehicleTypes.loading = true;
    });

    builder.addCase(getVehicleTypes.fulfilled, (state, action: any) => {
      if (action.payload) {
        const newListVehicleTypes: any = [...action.payload];
        state.vehicleTypes = {
          ...state.vehicleTypes,
          list: newListVehicleTypes,
          loading: false,
        };
      }
    });

    builder.addCase(getVehicleTypes.rejected, (state, action) => {
      state.vehicleTypes.loading = false;
    });
    // Get vehicleTypes

    // Get statistic
    builder.addCase(getStatistic.pending, (state, action) => {
      state.statistic.loading = true;
    });

    builder.addCase(getStatistic.fulfilled, (state, { payload }) => {
      if (payload) {
        state.statistic = {
          ...state.statistic,
          list: { ...payload },
          loading: false,
          error: false,
        };
      }
    });

    builder.addCase(getStatistic.rejected, (state, action) => {
      state.statistic.loading = false;
    });
    // End statistic
  },
});

const { actions, reducer: liveMapReducer } = liveMapSlice;
export const {
  setLoading,
  setParamSearch,
  setRowSelected,
  setTypeClick,
  setDefaultValue,
  setDefaultStatistic,
  updateBookings,
} = actions;
export default liveMapReducer;
