import Images from './image';

export const countries = {
  id: {
    name: 'Indonesia',
    languages: [
      { key: 'id', iconFlagSelected: Images.FLAG_ID_SELECTED, iconFlagUnselected: Images.FLAG_ID_UNSELECTED },
      { key: 'en', iconFlagSelected: Images.FLAG_EN_SELECTED, iconFlagUnselected: Images.FLAG_EN_UNSELECTED },
    ],
  },
  ph: {
    name: 'Philippines',
    languages: [
      // { key: 'en', iconFlagSelected: Images.FLAG_EN_SELECTED, iconFlagUnselected: Images.FLAG_EN_UNSELECTED },
    ],
  },
  th: {
    name: 'Thailand',
    languages: [
      { key: 'th', iconFlagSelected: Images.FLAG_TH_SELECTED, iconFlagUnselected: Images.FLAG_TH_UNSELECTED },
      { key: 'en', iconFlagSelected: Images.FLAG_EN_SELECTED, iconFlagUnselected: Images.FLAG_EN_UNSELECTED },
    ],
  },
  vn: {
    name: 'Vietnam',
    languages: [
      // { key: 'en', iconFlagSelected: Images.FLAG_EN_SELECTED, iconFlagUnselected: Images.FLAG_EN_UNSELECTED },
      // { key: 'th', iconFlagSelected: Images.FLAG_TH_SELECTED, iconFlagUnselected: Images.FLAG_TH_UNSELECTED },
      // { key: 'id', iconFlagSelected: Images.FLAG_ID_SELECTED, iconFlagUnselected: Images.FLAG_ID_UNSELECTED },
    ],
  },
};
