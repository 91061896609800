import { ContainerOutlined, DesktopOutlined, PictureOutlined, PieChartOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { RootState } from 'app/store';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Routing from 'routes';
import './Navigation.scss';

interface TypeNavigation {
  location: {
    pathname?: string;
  };
}

const MENU: { [key: string]: any } = {
  'live-map': ['1'],
  'booking-detail': ['4'],
  kpi: ['2'],
  contact: ['3'],
};

const NavigationModule: React.FC<TypeNavigation> = ({ location = {} }) => {
  const { defaultBookingId } = useSelector((state: any) => state.bookingDetail);
  const { hide_kpi_metrics_page } = useSelector((state: RootState) => state.common.settings?.settings || {});

  const getPathName = (location.pathname || '').split('/')[1];
  const regexID = getPathName.split('?')[0];

  const bookingDetailRedirect = () => {
    if (getPathName === 'booking-detail') {
      return;
    } else {
      if (defaultBookingId) {
        const path = `${Routing.BOOKING_DETAIL.path}${defaultBookingId}`;
        return path;
      }
      const path = Routing.BOOKING_DETAIL.path;
      return path;
    }
  };

  return (
    <Menu defaultSelectedKeys={MENU[regexID]} selectedKeys={MENU[regexID]} mode="horizontal">
      <Menu.Item key="1" icon={<DesktopOutlined />}>
        <NavLink to={Routing.LIVE_MAP.path}>{Routing.LIVE_MAP.name}</NavLink>
      </Menu.Item>

      <Menu.Item key="4" icon={<PictureOutlined />}>
        {/* @ts-ignore */}
        <NavLink to={() => bookingDetailRedirect}>{Routing.BOOKING_DETAIL.name}</NavLink>
      </Menu.Item>

      {!hide_kpi_metrics_page && (
        <Menu.Item key="2" icon={<PieChartOutlined />}>
          <NavLink to={Routing.KPI.path}>{Routing.KPI.name}</NavLink>
        </Menu.Item>
      )}

      <Menu.Item key="3" icon={<ContainerOutlined />}>
        <NavLink to={Routing.CONTACT.path}>{Routing.CONTACT.name}</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default NavigationModule;
