import React from 'react';
import i18n from 'i18n/i18n';

const LiveMapModule = React.lazy(() => import('features/live-map'));
const KpiModule = React.lazy(() => import('features/kpi'));
const ContactModule = React.lazy(() => import('features/contact'));
const SuccessLoginModule = React.lazy(() => import('features/workflows/success-login'));
const BookingDetailModule = React.lazy(() => import('features/booking-detail'));

const Routing = {
  LIVE_MAP: {
    path: '/live-map',
    component: LiveMapModule,
    exact: false,
    isPrivate: true,
    name: i18n.t('common.header_title.live_map'),
  },
  BOOKING_DETAIL: {
    path: '/booking-detail/',
    component: BookingDetailModule,
    exact: false,
    isPrivate: true,
    name: i18n.t('common.header_title.booking_details'),
  },
  BOOKING_DETAIL_ID: {
    path: '/booking-detail/:id',
    component: BookingDetailModule,
    exact: false,
    isPrivate: true,
    // name: 'Booking Details',
  },
  KPI: {
    path: '/kpi',
    component: KpiModule,
    exact: false,
    isPrivate: true,
    name: i18n.t('common.header_title.kpi_metrics'),
  },
  CONTACT: {
    path: '/contact',
    component: ContactModule,
    exact: false,
    isPrivate: true,
    name: i18n.t('common.header_title.support_contacts'),
  },

  // Workflows
  // - Success Login: It's handle callback from backend and update token to local storage
  SUCCESS_LOGIN: {
    path: '/success_login',
    component: SuccessLoginModule,
    exact: false,
    isPrivate: false,
    name: '',
  },
};

export default Routing;
