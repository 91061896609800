import ADDRESS from 'assets/images/address.svg';
import AVG_CHART_ICON from 'assets/images/avg-icon-chart.svg';
import ARROW_DOWN_ICON from 'assets/images/icon-arrowdown.svg';
import CHECK_ICON_FILTER from 'assets/images/icon-check-filter.svg';
import CHECK_ICON from 'assets/images/icon-check.svg';
import CLOCK_ICON from 'assets/images/icon-clock.svg';
import CLOCK_ICON_COMPLETED from 'assets/images/icon-clock-completed.svg';
import DESCRIPTION_ICON from 'assets/images/icon-description.svg';
import DESCRIPTION_ICON_COMPLETED from 'assets/images/icon-description-completed.svg';
import EXPRESS_MAIL from 'assets/images/icon-express-mail.svg';
import EXPRESS_MAIL_COMPLETED from 'assets/images/icon-express-mail-completed.svg';
import GOOGLE_ICON from 'assets/images/icon-google.svg';
import GOOGLE_ICON_COMPLETED from 'assets/images/icon-google-completed.svg';
import LOCATION_MAP_ICON from 'assets/images/icon-location-map.svg';
import LOCATION_MAP_ICON_COMPLETED from 'assets/images/icon-location-map-completed.svg';
import PHONE_ICON_2 from 'assets/images/icon-phone-2.svg';
import PHONE_ICON from 'assets/images/icon-phone.svg';
import USER_ICON from 'assets/images/icon-user-fillter.svg';
import USER_ICON_COMPLETED from 'assets/images/icon-user-fillter-completed.svg';
import VECTOR_ICON from 'assets/images/icon-vector.svg';
import LOGO_WHITE from 'assets/images/logo-white.svg';
import NO_DATA_CHART_ICON from 'assets/images/no-data.svg';
import MASK_ICON from 'assets/images/icon-mask.svg';
import SHARE_ICON from 'assets/images/icon-share.svg';
import DOWNLOAD_ICON from 'assets/images/icon-download.svg';
import DAYS_ROLLING from 'assets/images/7_days_rolling.svg';
import ICON_PLANE_WHITE from 'assets/images/icon-plane-white.svg';
import ICON_PHONE_WHITE from 'assets/images/icon-phone-white.svg';
import ICON_EMAIL_WHITE from 'assets/images/icon-email-white.svg';
import ICON_INFO from 'assets/images/icon-info.svg';
import ICON_TRAVEL from 'assets/images/icon-travel.svg';
import ICON_ENABLE_TRUCK from 'assets/images/enable-truck.svg';
import ICON_ENABLE_TRUCK_ACTIVE from 'assets/images/enable-truck-active.png';
import ICON_DISABLE_TRUCK from 'assets/images/disable-truck.svg';
//icon flag
import FLAG_EN_SELECTED from 'assets/flag-images/en-selected.png';
import FLAG_EN_UNSELECTED from 'assets/flag-images/en-unselected.png';
import FLAG_ID_SELECTED from 'assets/flag-images/id-selected.png';
import FLAG_ID_UNSELECTED from 'assets/flag-images/id-unselected.png';
import FLAG_TH_SELECTED from 'assets/flag-images/th-selected.png';
import FLAG_TH_UNSELECTED from 'assets/flag-images/th-unselected.png';
const TRUCK_ICON =
  'https://deliveree-public.s3-ap-southeast-1.amazonaws.com/photos/images/000/019/167/original/4_l_truck_192.png?1511160043';
const Images = {
  LOGO_WHITE,
  NO_DATA_CHART_ICON,
  AVG_CHART_ICON,
  TRUCK_ICON,
  CLOCK_ICON,
  DESCRIPTION_ICON,
  LOCATION_MAP_ICON,
  USER_ICON,
  GOOGLE_ICON,
  CHECK_ICON_FILTER,
  PHONE_ICON,
  EXPRESS_MAIL,
  PHONE_ICON_2,
  ADDRESS,
  CHECK_ICON,
  ARROW_DOWN_ICON,
  VECTOR_ICON,
  MASK_ICON,
  SHARE_ICON,
  DOWNLOAD_ICON,
  DESCRIPTION_ICON_COMPLETED,
  CLOCK_ICON_COMPLETED,
  USER_ICON_COMPLETED,
  LOCATION_MAP_ICON_COMPLETED,
  GOOGLE_ICON_COMPLETED,
  EXPRESS_MAIL_COMPLETED,
  DAYS_ROLLING,
  ICON_EMAIL_WHITE,
  ICON_PLANE_WHITE,
  ICON_PHONE_WHITE,
  ICON_INFO,
  ICON_TRAVEL,
  ICON_ENABLE_TRUCK,
  ICON_ENABLE_TRUCK_ACTIVE,
  ICON_DISABLE_TRUCK,

  //icon flag
  FLAG_EN_SELECTED,
  FLAG_EN_UNSELECTED,
  FLAG_ID_SELECTED,
  FLAG_ID_UNSELECTED,
  FLAG_TH_SELECTED,
  FLAG_TH_UNSELECTED,
};

export default Images;
