import axiosClient from './axiosClient';

class BookingDetailApi {
  getBookings = (payload: Object) => {
    const url = '/bookings';
    return axiosClient.get(url, payload);
  };

  getBookingById = (id: number) => {
    const url = `/bookings/${id}`;
    return axiosClient.get(url);
  };

  downloadAttachments = async (bookingId, locationId) => {
    const url = `/bookings/${bookingId}/locations/${locationId}/download_attachments`;
    try {
      const data = (await axiosClient.get(url)) as { object };
      this.downloadAction(data?.object?.url);
    } catch (error) {
      throw error;
    }
  };

  downloadAllAttachments = async (id) => {
    const url = `/bookings/${id}/download`;
    try {
      const data = (await axiosClient.get(url)) as { object };
      this.downloadAction(data?.object?.url);
    } catch (error) {
      throw error;
    }
  };

  downloadSenderAttachments = async (id) => {
    const url = `/bookings/${id}/booking_attachments/download`;
    try {
      const data = (await axiosClient.get(url)) as { object };
      this.downloadAction(data?.object?.url);
    } catch (error) {
      throw error;
    }
  };

  downloadAction = (url) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      throw error;
    }
  };
}

const bookingDetailApi = new BookingDetailApi();
export default bookingDetailApi;
