import axiosClient from './axiosClient';

class VehiclesApi {
  getVehicles = (payload: Object) => {
    const url = 'vehicles';
    return axiosClient.get(url, payload);
  };

  getVehicleTypes = () => {
    const url = 'vehicle_types';
    return axiosClient.get(url);
  };
}

const vehiclesApi = new VehiclesApi();
export default vehiclesApi;
