/** @var {resources} */
const resources = {
  en: {
    translation: {
      common: require('./locales/common/en.json'),
      liveMap: require('./locales/live-map/en.json'),
      bookingDetail: require('./locales/booking-detail/en.json'),
      kpi: require('./locales/kpi-metrics/en.json'),
    },
  },
  id: {
    translation: {
      common: require('./locales/common/id.json'),
      liveMap: require('./locales/live-map/id.json'),
      bookingDetail: require('./locales/booking-detail/id.json'),
      kpi: require('./locales/kpi-metrics/id.json'),
    },
  },
  th: {
    translation: {
      common: require('./locales/common/th.json'),
      liveMap: require('./locales/live-map/th.json'),
      bookingDetail: require('./locales/booking-detail/th.json'),
      kpi: require('./locales/kpi-metrics/th.json'),
    },
  },
  tl: {
    translation: {
      common: require('./locales/common/tl.json'),
      liveMap: require('./locales/live-map/tl.json'),
      bookingDetail: require('./locales/booking-detail/tl.json'),
    },
  },
};

export default resources;
