import { RootState } from 'app/store';
import NotFoundPage from 'components/NotFoundPage';
import PrivateRoute from 'components/PrivateRoute';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Routing from 'routes';

type ValueOf<T> = T[keyof T];

type TypeRouteList = { routes: any } & typeof defaultProps;
const defaultProps = {
  routes: {},
};

const RouteList: React.FC<TypeRouteList> = ({ routes }) => {
  const { hide_kpi_metrics_page } = useSelector((state: RootState) => state.common.settings?.settings || {});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const routeList = Object.keys(routes).reduce((res: ValueOf<typeof Routing>[], key) => {
    if (key === 'KPI' && hide_kpi_metrics_page === true) {
      return res;
    }

    return [...res, routes[key]];
  }, []);

  if (routeList.length === 0) {
    return null;
  }

  return (
    <Switch>
      {routeList.map((route) =>
        route.isPrivate ? (
          <PrivateRoute key={route.path} exact={route.exact} path={route.path} component={route.component} />
        ) : (
          <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
        )
      )}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default RouteList;
