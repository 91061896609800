import { Button, Form, Input, message, Modal, Upload } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowUp } from 'assets/images/arrow-up.svg';
import { ReactComponent as Trash } from 'assets/images/trash.svg';
import { ReactComponent as BlankAvatarLg } from 'assets/images/blank-avatar-lg.svg';
import { UploadChangeParam } from 'antd/lib/upload';
import { editCOCAccount } from 'app/commonSlice';
import { useDispatch } from 'react-redux';

type InternalUploadFile = {
  originFileObj?: File;
};

type AvatarAttributes = {
  id?: number;
  image?: Blob;
  _destroy?: boolean;
};

type Values = {
  name: string;
  image: [InternalUploadFile];
};

export type EditProfileModalProps = { visible: boolean; data: any; onClose: () => void };

export const EditProfileModal: React.FC<EditProfileModalProps> = ({ visible, data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultAvatar = data?.avatar?.image_url;

  const [form] = Form.useForm<Values>();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(defaultAvatar);
  const [isAvatarValid, setIsAvatarValid] = useState(false);

  useEffect(() => {
    if (visible) {
      setImageUrl(defaultAvatar);
    }

    return () => form.resetFields();
  }, [visible]); // eslint-disable-line

  function onFinish() {
    if (!data) {
      return;
    }

    form
      .validateFields()
      .then(async (values) => {
        const avatarAttributes: AvatarAttributes = {};
        if (data.avatar) {
          avatarAttributes.id = data.avatar?.id;
        }

        const file = values.image[0]?.originFileObj;
        if (!file && !imageUrl) {
          avatarAttributes._destroy = true;
        } else if (file) {
          avatarAttributes.image = file;
        } else avatarAttributes._destroy = false;

        const formData = new FormData();
        formData.append('name', values.name);

        for (const [key, value] of Object.entries(avatarAttributes)) {
          formData.append(`avatar_attributes[${key}]`, value as Blob);
        }

        setIsLoading(true);
        await dispatch(editCOCAccount(formData));
        onClose();
      })
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }

  function normFile(e: UploadChangeParam<Blob>) {
    if (!isAvatarValid) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => setImageUrl(reader.result));
    reader.readAsDataURL(e.file);

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  }

  function handleRemoveFile() {
    form.resetFields(['image']);
    setImageUrl(null);
  }

  function beforeUpload(file) {
    const isJpgOrPngOrJpeg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPngOrJpeg) {
      message.error(t('common.image_type_error_message'));
    }
    const isLt8M = file.size / 1024 / 1024 < 8;
    if (!isLt8M) {
      message.error(t('common.image_size_error_message'));
    }

    setIsAvatarValid(isJpgOrPngOrJpeg && isLt8M);
    return false;
  }

  return (
    <Modal
      title={t('common.editMyProfile')}
      className="profile-modal"
      visible={visible}
      width={760}
      onOk={onFinish}
      onCancel={onClose}
      footer={[
        <Button key="submit" className="ant-btn--gray-outline" disabled={isLoading} onClick={onClose}>
          {t('common.cancel')}
        </Button>,
        <Button className="ant-btn--primary" type="primary" disabled={isLoading} onClick={onFinish}>
          {t('common.save')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        className="profile-modal__inner"
        initialValues={{
          name: data?.name,
          image: [],
        }}
        onFinish={onFinish}
      >
        <div>
          <Form.Item name="name" label={t('common.fullName')}>
            <Input />
          </Form.Item>
          <Form.Item label={t('common.username')}>
            <Input disabled defaultValue={data?.user_id} />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            className="profile-modal__note"
            label={
              <span>
                {t('common.photo')}
                <i>({t('common.optional')})</i>
              </span>
            }
          >
            <Avatar shape="square" size={117} src={imageUrl} icon={<BlankAvatarLg />} />
            <div className="profile-modal__file-actions">
              <Form.Item name="image" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                <Upload beforeUpload={beforeUpload} showUploadList={false} maxCount={1} accept=".jpg, .jpeg, .png">
                  <Button type="link" icon={<ArrowUp />}>
                    {t('common.uploadNew')}
                  </Button>
                </Upload>
              </Form.Item>
              <Button type="link" icon={<Trash />} onClick={handleRemoveFile}>
                {t('common.delete')}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default EditProfileModal;
