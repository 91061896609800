import { Layout } from 'antd';
import { RootState } from 'app/store';
import Navigation from 'components/Navigation';
import { countries } from 'constants/countriesLanguage';
import Images from 'constants/image';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCookie, setCookie } from 'utils/helpers/cookie';
import Profile from './Profile';
import './Header.scss';

const { Header } = Layout;

interface TypeHeader {
  location: {
    pathname?: string;
  };
}
const defaultSelectCountry = {
  default: {
    languages: [
      { key: 'en', iconFlagSelected: Images.FLAG_EN_SELECTED, iconFlagUnselected: Images.FLAG_EN_UNSELECTED },
    ],
  },
};

const HeaderModule: React.FC<TypeHeader> = ({ location = {} }) => {
  const { t, i18n } = useTranslation();
  const settings = useSelector((state: RootState) => state.common.settings);
  const { country_code: countryCode } = settings;
  const [activeLanguage, setActiveLanguage] = useState('en');

  useEffect(() => {
    getLanguage();
  }, []);

  const getLanguage = () => {
    // let language: any;
    // if (!countries[countryCode]?.languages?.length) {
    //   language = localStorage.getItem('language');
    // } else {
    const language = getCookie('language');
    // }

    if (language) {
      setActiveLanguage(language);
      i18n.changeLanguage(language);
    }
  };

  const renderFlagLanguage = () => {
    return countries[countryCode]?.languages?.map((item) => (
      <span key={item.key} style={{ marginRight: '10px' }} onClick={() => onChangeLanguage(item.key)}>
        <img
          className={activeLanguage === item.key ? 'select' : 'unselect'}
          src={item.iconFlagSelected}
          alt={item.key}
        />
      </span>
    ));
  };
  /*-----------------------------------------------------------*/
  /*------------------ FUNCTION HANDLE LOGIC ------------------*/
  /*-----------------------------------------------------------*/

  const handleReload = () => window.location.reload();

  /*--------------------------------------------*/
  /*------------------ LAYOUT ------------------*/
  /*--------------------------------------------*/

  function onChangeLanguage(lang) {
    if (activeLanguage !== lang) {
      setCookie('language', lang, 1);
      window.location.reload();
    }
  }

  return (
    <Header className="app__header">
      <div className="app__header_group">
        <div className="header__group-logo" onClick={() => handleReload()}>
          <img src={get(settings, 'logo.image_url', '')} alt="" style={{ margin: '0 10px 0 0' }} />
          <div className="relative">
            <span className="powered-by">{t('common.powered_by')}</span>
            <img src={get(settings, 'company_logo.image_url', '')} alt="" />
          </div>
        </div>
      </div>
      <div className="user-info">
        <span>{renderFlagLanguage()}</span>
        <Profile />
      </div>
      <Navigation location={location} />
    </Header>
  );
};

export default HeaderModule;
