import { AUTH_DEV_URL } from 'constants/app';

export function injectAuthToDomain() {
  if (process.env.NODE_ENV === 'development') {
    return AUTH_DEV_URL || window.location.origin;
  }

  const originArr = window.location.origin.split('.');
  const cocName = originArr.shift();
  return [cocName, 'auth', ...originArr].join('.');
}
