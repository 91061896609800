import axiosClient from './axiosClient';
import { injectAuthToDomain } from 'utils/injectAuthToDomain';

class CommonApi {
  getSettings = () => {
    const url = 'api/v1/coc_profiles/profile';
    const options = {
      baseURL: injectAuthToDomain(),
    };

    return axiosClient.get(url, options);
  };

  getFleetPartner = (fleetParterID: number) => {
    const url = `/fleet_partners/${fleetParterID}`;
    return axiosClient.get(url);
  };

  getCOCAccount = () => {
    const url = `api/v1/coc_accounts/me`;
    const options = {
      baseURL: injectAuthToDomain(),
    };

    return axiosClient.get(url, options);
  };

  editCOCAccount = (body) => {
    const url = '/coc_accounts/me';
    return axiosClient.put(url, body);
  };
}

const commonApi = new CommonApi();
export default commonApi;
