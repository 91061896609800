import axiosClient from './axiosClient';
import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import StorageKeys from 'constants/storage-keys';
import { WEBAPP_API_URL } from 'constants/app';

class BookingApi {
  getBookings = (payload: Object) => {
    const url = '/bookings';
    return axiosClient.get(url, payload);
  };

  getBookingLocations = (payload: Object) => {
    const url = '/locations';
    return axiosClient.get(url, payload);
  };

  getFinalDesETA = (id: number, cancelToken?: CancelToken) => {
    const url = `${WEBAPP_API_URL}/tally_api/v1/bookings/${id}`;

    let config: AxiosRequestConfig = {
      headers: {
        'content-type': 'application/json',
        'Device-Type': 'Webapp',
        Authorization: localStorage.getItem(StorageKeys.ACCESS_TOKEN),
      },
      cancelToken,
    };
    return axios.get(url, config);
  };
  getStatistic = (payload: Object) => {
    const url = '/bookings/statistics';
    return axiosClient.get(url, payload);
  };

  // Remove from Backend - we decide do not use this api
  // getLocation = (payload: { locationID: string, params: Object }) => {
  //   const { locationID, params } = payload;
  //   const url = `locations/${locationID}`;

  //   return axiosClient.get(url, { params });
  // };
}

const bookingApi = new BookingApi();
export default bookingApi;
