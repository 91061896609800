import StorageKeys from 'constants/storage-keys';
import React from 'react';
import { Route } from 'react-router-dom';
import { CALLBACK_URL } from 'constants/app';
import Routing from 'routes';
import { injectAuthToDomain } from 'utils/injectAuthToDomain';

type ApplicationsProps = {
  exact: boolean;
  path: string;
  component: any;
};

const PrivateRoute: React.FC<ApplicationsProps> = ({ exact, path, component: Component }) => (
  <Route
    exact={exact}
    path={path}
    render={(routeProps) => {
      // GET ACCESS_TOKEN
      const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
      if (!accessToken) {
        // TODO:
        // - Handle callback from other url to detect token of user use for this project
        //
        // SPECS:
        // - Workflow login
        // -- Because we don't have a login page in project so if user want to use all features
        // ---- in project. We need to redirect user to another domain to process login
        // -- After user successful login. In this project wil receive the callback and
        // ---- update token from callback to local storage

        const url = `${injectAuthToDomain()}/coc_accounts/sign_in?redirect_uri=${CALLBACK_URL}${
          Routing.SUCCESS_LOGIN.path
        }/`;
        window.location.href = url;
        return null;
      }

      return <Component {...routeProps} />;
    }}
  />
);

export default PrivateRoute;
