import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Routing from 'routes';

export const NotFoundPage: React.FC<RouteComponentProps> = ({ location }) => {
  const { t } = useTranslation();
  if (location.pathname === Routing.KPI.path) {
    message.error({ content: t('common.kpiIsTurnOff'), duration: 10 });
  }

  return <Redirect to={Routing.LIVE_MAP.path} />;
};

export default NotFoundPage;
