export const API_URL = process.env.REACT_APP_API_URL;
export const WEBAPP_API_URL = process.env.REACT_APP_WEBAPP_API_URL;

// export const CALLBACK_URL = process.env.REACT_APP_CALLBACK_URL;
export const CALLBACK_URL = window.location.origin;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
export const COC_CLIENT_ID = process.env.REACT_APP_COC_CLIENT_ID;
export const AUTH_DEV_URL = process.env.REACT_APP_AUTH_DEV_URL;

export const FIREBASE_ENV = process.env.REACT_APP_FIREBASE_ENV;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const FIREBASE_CONFIG = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  ...(!!FIREBASE_MEASUREMENT_ID ? { measurementId: FIREBASE_MEASUREMENT_ID } : {}), //measurementId is optional
};

export const RAILS_ENV = process.env.REACT_APP_RAILS_ENV;
export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY;
export const GMAPS_BROWSER_KEY = process.env.REACT_APP_GMAPS_BROWSER_KEY;
export const GMAPS_API_VERSION = process.env.REACT_APP_GMAPS_API_VERSION;
export const MAP_STYLE = JSON.parse(process.env.REACT_APP_MAP_STYLE || '');
