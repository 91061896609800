import i18n from 'i18n/i18n';

export const STATUS_KEY: any = {
  DELIVERY_IN_PROGRESS: 'delivery_in_progress',
  DRIVER_ACCEPT_BOOKING: 'driver_accept_booking',
  LOCATING_DRIVER: 'locating_driver',
  LOCATING_DRIVER_TIMEOUT: 'locating_driver_timeout',
  FLEET_ACCEPT_BOOKING: 'fleet_accept_booking',
  ASSIGNING_DRIVER: 'assigning_driver',
  ASSIGNING_DRIVER_TIMEOUT: 'assigning_driver_timeout',
  DRIVER_DECLINED_BOOKING: 'driver_declined_booking',
  DELIVERY_COMPLETED: 'delivery_completed',
  CANCELED: 'canceled',
  CANCELED_REBOOK: 'canceled_rebook',
  UNDELIVERABLE: 'undeliverable',
  CS_FINDING_DRIVER: 'cs_finding_driver',
  FLEET_TIMEOUT: 'fleet_timeout',
};
export const STATUS_SEARCH: any = {
  ASSIGNING: { key: 'assigning', name: i18n.t('common.status.assigning') },
  BOOKED: { key: 'booked', name: i18n.t('common.status.booked') },
  IN_PROGRESS: { key: 'in_progress', name: i18n.t('common.status.in_progress') },
  COMPLETED: { key: 'completed', name: i18n.t('common.status.completed') },
  CANCELED: { key: 'canceled', name: i18n.t('common.status.canceled') },
  RESCHEDULED: { key: 'rescheduled', name: i18n.t('common.status.rescheduled') },
};
export const HOURS = 'HOURS';
export const DATE = 'DATE';
export const DATE_FORMAT_PARAM = 'YYYY-MM-DD';
export const EMAIL = 'EMAIL';
export const PHONE = 'PHONE';

export const LIST_PARAMS_SEARCH_BOOKING = {
  VEHICLE_TYPE_ID: 'vehicle_type_ids[]',
  STATUSES: 'statuses[]',
  PICKUP_DATE: 'pick_up_date[]',
  PICKUP_TIME: 'pick_up_time[]',
  FINAL_ETA_DATE: 'final_eta_date[]',
  FINAL_ETA_TIME: 'final_eta_time[]',
  SORT_BY: 'sort_by',
  SORT_DIR: 'sort_dir',
  PAGE: 'page',
  PER_PAGE: 'per_page',
};

export const DEFAULT_SORT_BY = 'status';
export const ID = 'id';
export const VEHICLE_TYPE_AND_PLATE_NUMBER = 'vehicle_type_and_plate_number';
export const TOTAL_DISTANCE = 'total_distance';
export const STATUS = 'status';
export const PICKUP_TIME = 'pickup_time';
export const ORIGIN_ADDRESS = 'originAddress';
export const NEXT_DESTINATION_ADDRESS = 'nextDestinationAddress';
export const ETA = 'eta';

export const TABLE_ROW = 'row';
export const VEHICLE = 'vehicle';

export const ZOOM_CLICK_VEHICLE = 13;

export const TALLY_STATUS = {
  WAITING_PICKUP: 'waiting_for_pickup',
  ON_SCHEDULE: 'on_schedule',
  EARLY: 'early',
  LATE: 'late',
  REMAINING: 'remaining',
  OVER_TIME: 'over_time',
  NOW: 'now',
};

export const DEFAULT_PAGESIZE = 50;

export const VEHICLE_TYPE_NAME = 'vehicle_type_name';
export const STANDARD_FEES = 'standard_fees';
export const EXTRA_SERVICE_FEES = 'extra_service_fees';
export const REIMBURSEMENTS_FEES = 'reimbursements_fees';
export const TOTAL_COST = 'total_cost';
export const AVG_COST_DAY = 'avg_cost_day';
export const AVG_COST_BOOKING_DAY = 'avg_cost_booking_day';
export const AVG_LOADING_TIME = 'avg_loading_time';
export const AVG_UNLOADING_TIME = 'avg_unloading_time';
export const AVG_TRAVELED_TIME = 'avg_traveled_time';
export const AVG_DRIVER_WORK_TIME = 'avg_driver_work_time';
export const COMPLETED_MONTH = 'completed_month';

export const RECENT_IN_DETAILS = 'recents_in_details';
export const SEARCH_IN_DETAILS = 'search_in_details';
