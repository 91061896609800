import Images from 'constants/image';
import React from 'react';
import './Loading.scss';

const Loading: React.FC<{}> = () => (
  <div className="loading">
    <div className="loading__circle">
      <img className="loading__icon" src={Images.LOGO_WHITE} alt="company icon" />
    </div>
  </div>
);

export default Loading;
