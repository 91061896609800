import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import 'moment/locale/id';
import 'moment/locale/th';
import { DATE_FORMAT_PARAM } from 'constants/common';

import { LAST_30_DAYS, LABEL_DEFAULT } from 'constants/kpiConstants';
import { getCookie } from 'utils/helpers';

const language = getCookie('language');
if (language) {
  moment.locale(language);
}
// set default date to get chart data
const defaultStartDate = moment().subtract(LAST_30_DAYS, 'days').format(DATE_FORMAT_PARAM);
const defaultEndDate = moment().subtract(1, 'days').format(DATE_FORMAT_PARAM);

type DiffDay = {
  startDate: string;
  endDate: string;
  label: string;
};

type KpiState = {
  loading: boolean;
  diffDayBookingVolume: DiffDay;
  diffDayVehicleUtilization: DiffDay;
  diffDayCostAnalysis: DiffDay;
  diffDayEmployeeActivity: DiffDay;
};

// ---------- Init Reducer ---------- //

export const defaultDiffDay = {
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  label: LABEL_DEFAULT,
};

const initialState: KpiState = {
  loading: false,
  diffDayBookingVolume: defaultDiffDay,
  diffDayVehicleUtilization: defaultDiffDay,
  diffDayCostAnalysis: defaultDiffDay,
  diffDayEmployeeActivity: defaultDiffDay,
};

// Slice
const kpiSlice = createSlice({
  name: 'kpi',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<{ value: boolean }>) => {
      state.loading = action.payload.value;
    },
    setDiffDayBookingVolume: (state, action: PayloadAction<DiffDay>) => {
      state.diffDayBookingVolume = action.payload;
    },
    setDiffDayVehicleUtilization: (state, action: PayloadAction<DiffDay>) => {
      state.diffDayVehicleUtilization = action.payload;
    },
    setDiffDayCostAnalysis: (state, action: PayloadAction<DiffDay>) => {
      state.diffDayCostAnalysis = action.payload;
    },
    setDiffEmployeeActivity: (state, action: PayloadAction<DiffDay>) => {
      state.diffDayEmployeeActivity = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

const { actions, reducer: kpiReducer } = kpiSlice;
export const {
  setLoading,
  setDiffDayBookingVolume,
  setDiffDayVehicleUtilization,
  setDiffDayCostAnalysis,
  setDiffEmployeeActivity,
} = actions;
export default kpiReducer;
