import { getCookie } from './../utils/helpers/cookie';
/**
 * API Gateway for this App
 *
 * - All http requests must go through this gateway
 * - This client will add stuffs for every requests like headers, baseURL, ...
 * - This is also a good place to handle errors as captured in interceptors.
 */

import { message } from 'antd';
import axios from 'axios';
import { API_URL, CALLBACK_URL } from 'constants/app';
import StorageKeys from 'constants/storage-keys';
import i18n from 'i18next';
import queryString from 'query-string';
import Routing from 'routes';
import { injectAuthToDomain } from 'utils/injectAuthToDomain';

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request-config` for the full list of configs
const axiosClient = axios.create({
  baseURL: API_URL,
  headers: {
    'content-type': 'application/json',
    'Device-Type': 'Webapp',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // One magic thing about token is NEVER EXPIRED :P
  // Attach auth header using token from local storage
  const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);

  if (accessToken && !config.headers.Authorization) {
    config.headers.Authorization = accessToken;
  }

  if (config.headers.isRemoveAuthorization) {
    delete config.headers.Authorization;
    delete config.headers.isRemoveAuthorization;
  }

  if (!config.headers['Accept-Language']) {
    config.headers['Accept-Language'] = i18n.language || window.localStorage.i18nextLng || 'en';
  }

  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      //refresh token when user call api (unCheck remember me in login)
      if (response?.data?.token?.value) {
        localStorage.setItem(StorageKeys.ACCESS_TOKEN, response?.data?.token?.value);
      }
      return response.data;
    }

    return response;
  },
  (error) => {
    // Handle errors
    let errorMessage = error;
    if (error.response) {
      errorMessage = error.response.data ? error.response.data.error : error.response.data;

      if (error.response.status === 401) {
        const lang = getCookie('language');
        message.error(errorMessage);
        localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
        const url = `${injectAuthToDomain()}/coc_accounts/sign_in?redirect_uri=${CALLBACK_URL}${
          Routing.SUCCESS_LOGIN.path
        }/&lang=${lang}`;
        window.location.href = url;
      }
    }

    throw new Error(errorMessage);
  }
);

export default axiosClient;
