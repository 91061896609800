import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import bookingDetailApi from 'api/bookingDetailApi';
import { showNotifyErrorCatch } from 'utils/logger';
import { STATUS_SEARCH, RECENT_IN_DETAILS, SEARCH_IN_DETAILS } from 'constants/common';
import { AxiosResponse } from 'axios';

type AxiosResponseType = {
  pagination: Object;
} & AxiosResponse;

// ---------- Thunk API ---------- //

export const getBookings = createAsyncThunk('bookingDetail/getBooking', async (payload: any, thunkAPI) => {
  const { section_type } = payload.params;
  let group1: any = {};
  let group2: any = {};
  let payload1 = JSON.parse(JSON.stringify(payload));
  let payload2 = JSON.parse(JSON.stringify(payload));

  if (section_type === RECENT_IN_DETAILS) {
    payload1.params['statuses[]'] = [STATUS_SEARCH.IN_PROGRESS.key, STATUS_SEARCH.BOOKED.key];
    payload2.params['statuses[]'] = [STATUS_SEARCH.COMPLETED.key];
  }

  if (section_type === SEARCH_IN_DETAILS) {
    payload1.params['statuses[]'] = [
      STATUS_SEARCH.IN_PROGRESS.key,
      STATUS_SEARCH.BOOKED.key,
      STATUS_SEARCH.ASSIGNING.key,
    ];

    payload2.params['statuses[]'] = [
      STATUS_SEARCH.CANCELED.key,
      STATUS_SEARCH.RESCHEDULED.key,
      STATUS_SEARCH.COMPLETED.key,
    ];
  }
  try {
    const response1 = (await bookingDetailApi.getBookings(payload1)) as AxiosResponseType;
    const response2 = (await bookingDetailApi.getBookings(payload2)) as AxiosResponseType;

    group1 = response1;
    group2 = response2;

    return {
      type: section_type,
      group1,
      group2,
    };
  } catch (error) {
    showNotifyErrorCatch(error);
  }
});

export const getBookingDetail = createAsyncThunk('bookingDetail/getBookingDetail', async (id: number, thunkAPI) => {
  try {
    const response = await bookingDetailApi.getBookingById(id);

    return response;
  } catch (error) {
    showNotifyErrorCatch(error);
  }
});

export const getOnGoingBookings = createAsyncThunk(
  'bookingDetail/getOnGoingBookings',
  async (payload: any, thunkAPI) => {
    try {
      const response = (await bookingDetailApi.getBookings(payload)) as AxiosResponseType;

      return {
        response,
      };
    } catch (error) {
      showNotifyErrorCatch(error);
    }
  }
);

export const getPastBookings = createAsyncThunk('bookingDetail/getPastBookings', async (payload: any, thunkAPI) => {
  try {
    const response = (await bookingDetailApi.getBookings(payload)) as AxiosResponseType;

    return {
      response,
    };
  } catch (error) {
    showNotifyErrorCatch(error);
  }
});

// ---------- Init Reducer ---------- //

type State = {
  bookings: {
    type: string;
    group1: {
      data: any[];
      pagination?: any;
    };
    group2: {
      data: any[];
      pagination?: any;
    };
  };
  pastBookings: {
    data: any[];
    pagination?: any;
  };
  bookingDetail?: any;
  loading: boolean;
  error: boolean;
  defaultBookingId?: string | number;
};

const initialState: State = {
  bookings: {
    type: '',
    group1: {
      data: [],
    },
    group2: {
      data: [],
    },
  },
  pastBookings: {
    data: [],
  },
  bookingDetail: {},
  defaultBookingId: '',
  loading: false,
  error: false,
};

// ---------- Slice ---------- //

const bookingDetailSlice = createSlice({
  name: 'bookingDetail',
  initialState,
  reducers: {
    setDefaultBookingId: (state, action) => {
      state.defaultBookingId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get getBookings
    builder.addCase(getBookings.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getBookings.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.bookings = {
          type: action.payload.type,
          group1: action.payload.group1,
          group2: action.payload.group2,
        };

        state.loading = false;
      }
    });

    builder.addCase(getBookings.rejected, (state, action) => {
      state.loading = false;
    });
    // Get getBookings

    // Get getBookingDetail
    builder.addCase(getBookingDetail.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getBookingDetail.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.bookingDetail = { ...action.payload.object };
        state.loading = false;
      }
    });

    builder.addCase(getBookingDetail.rejected, (state, action) => {
      state.loading = false;
    });
    // Get getBookingDetail

    // Get getOnGoingBookings
    builder.addCase(getOnGoingBookings.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getOnGoingBookings.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.bookings = {
          ...state.bookings,
          group1: {
            data: [...state.bookings.group1.data, ...action?.payload?.response?.data],
            pagination: { ...action?.payload?.response?.pagination },
          },
        };
        state.pastBookings = { ...action.payload.response };

        state.loading = false;
      }
    });

    builder.addCase(getOnGoingBookings.rejected, (state, action) => {
      state.loading = false;
    });
    // Get getOnGoingBookings

    // Get getPastBookings
    builder.addCase(getPastBookings.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getPastBookings.fulfilled, (state, action: any) => {
      if (action.payload) {
        state.bookings = {
          ...state.bookings,
          group2: {
            data: [...state.bookings.group2.data, ...action?.payload?.response?.data],
            pagination: { ...action?.payload?.response?.pagination },
          },
        };

        state.loading = false;
      }
    });

    builder.addCase(getPastBookings.rejected, (state, action) => {
      state.loading = false;
    });
    // Get getPastBookings
  },
});

const { actions, reducer: bookingDetailReducer } = bookingDetailSlice;
export const { setDefaultBookingId } = actions;
export default bookingDetailReducer;
