import moment from 'moment';
import _ from 'lodash';
import I18n from 'i18next';

// import I18n from 'i18n/i18n';
import { AsyncThunkAction } from '@reduxjs/toolkit';
export const truncateString = (value: string, char: number) => {
  if (value) {
    return value.length > char ? `${value.substr(0, char)}...` : value;
  }
  return '';
};

export const getArrayDiffDays = (fromDate: string, toDate: string) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffDays = Math.round(Math.abs((new Date(fromDate).getTime() - new Date(toDate).getTime()) / oneDay));
  const arrayDiffDays: any = [];
  for (let i = 0; i <= diffDays; i += 1) {
    arrayDiffDays.push(moment(fromDate).add(i, 'days').format('yyyy-MM-DD'));
  }
  return arrayDiffDays;
};

export const formatNumberByCountry = (number: any, precision = 1) => {
  const config_reducer = localStorage.getItem('config_reducer');
  if (config_reducer) {
    let { country_code: countryCode } = JSON.parse(config_reducer).settings || 'vn';
    if (number) {
      if (countryCode === 'vn') return roundNumber(number, precision).toLocaleString('vi');
      if (countryCode && countryCode !== 'tl') {
        return roundNumber(number, precision).toLocaleString(countryCode);
      }
      return roundNumber(number, precision).toLocaleString();
    } else {
      return 0;
    }
  }
};

export const formatDistance = (distance: number, Unit = 'km') => {
  const distanceUnit = I18n.t(`common.short_distance_unit.${Unit}`);
  return `${formatNumberByCountry(distance, 1)} ${distanceUnit}`;
};

export const formatFee = (totalPrice: any, precision = 1, forceZeroValue = undefined) => {
  // if (!totalPrice) return '';

  const config_reducer = localStorage.getItem('config_reducer');
  if (config_reducer) {
    let { currency } = JSON.parse(config_reducer).settings || '₫';
    if (!_.isUndefined(forceZeroValue) && totalPrice === 0) return forceZeroValue;
    if (currency !== undefined) {
      return `${currency} ${formatNumberByCountry(totalPrice, precision)}`;
    }
  }

  return formatNumberByCountry(totalPrice, precision);
};

export const formatPercentage = (percentage: any) => {
  if (!percentage) {
    return 0 + '%';
  }
  return `${formatNumberByCountry(percentage, 1)}%`;
};

export const hourFormatByCountry = () => {
  const config_reducer = localStorage.getItem('config_reducer');
  if (config_reducer) {
    let { country_code: countryCode } = JSON.parse(config_reducer).settings || 'vn';
    let formatHour;

    switch (countryCode?.toLowerCase()) {
      case 'ph':
        formatHour = 'h:mm a';
        break;
      default:
        formatHour = 'H:mm a';
    }
    return formatHour;
  }
};

export const getSettingTimeZone = () => {
  let timeZone = '+0700';
  try {
    const config_reducer = localStorage.getItem('config_reducer');
    if (config_reducer) {
      const { settings } = JSON.parse(config_reducer);
      timeZone = settings.settings.time_zone;
    }
  } catch (ex) {
    console.error(ex);
  }
  return timeZone;
};

export const formatDateTime = (dateTime: any) => {
  moment.locale('en');

  const formatHour = hourFormatByCountry();
  const timeZone = getSettingTimeZone();

  const mmDateTime = timeZone ? moment(dateTime).utcOffset(timeZone) : moment(dateTime);
  const month = I18n.t(`common.format_short_month.month_${mmDateTime.month()}`);
  return {
    date: mmDateTime.format(`DD-[${month}]-YY`),
    time: mmDateTime.format(formatHour),
    fullDate: mmDateTime.format(`DD-[${month}]-YYYY`),
    dateAndTime: mmDateTime.format(`DD-[${month}]-YY, ${formatHour}`),
    timeAndDate: mmDateTime.format(`${formatHour}, DD-[${month}]-YY`),
    shortDateAndTime: mmDateTime.format(`DD-[${month}], ${formatHour}`),
    shortTimeAndDate: mmDateTime.format(`${formatHour}, DD-[${month}]`),
    month: mmDateTime.format(`[${month}]`),
    monthAndYear: mmDateTime.format(`[${month}] YYYY`),
    dayOfWeekAndDateTime: mmDateTime.format(`ddd, ${formatHour}, DD-[${month}]-YY`),
  };
};

export const roundNumber = (value, precision = 1) => {
  if (value === 0) return 0;
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const numberWithCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

export function createTakeLatest() {
  let lastPromise: ReturnType<AsyncThunkAction<any, number, {}>> | null = null;

  function takeLatest(promise) {
    if (lastPromise) {
      lastPromise.abort();
    }
    lastPromise = promise;
  }

  return takeLatest;
}

export function pluralCheck(count, title, titlePlural) {
  return count > 1 ? titlePlural : title;
}

export function checkPeriodToShowVehicle(pickupTime: string, vehicleSettingTime: number = 2) {
  if (!pickupTime || !vehicleSettingTime) return false;
  const now = new Date();

  const duration = moment.duration(moment(pickupTime).diff(moment(now)));
  const hours = duration.asHours();

  return hours <= vehicleSettingTime;
}
